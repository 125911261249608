import React, { useState, useEffect } from "react";
import Header from "../shared/Header";
import { Link } from "react-router-dom";
import { Container, Form } from "react-bootstrap";
import * as Yup from "yup";
import { useFormik } from "formik";
import arrow from "../../assets/img/arrow.svg";
import FormInput from "../shared/FormInput";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import Loading from "../shared/Loading";
import {
  getDress,
  clearDressValues,
  saveSingleDress,
  clearValues,
} from "../../features/dress/dressSlice";
import moment from "moment";
import { formatFullMonthDate } from "../../helpers/helpers";
import { useLocation } from "react-router-dom";
import { setFixed } from "../../features/store/storeSlice";

export default function ViewOrder() {
  const location = useLocation();
  const {
    isLoading,
    dress_image,
    dress_name,
    style_number,
    dress_price,
    dress_colors,
    dress_sizes,
    estimated_months,
    estimated_days,
    date,
    selected_colorLabel,
    colorIdNew,
    selected_sizeLabel,
    sizeIdNew,
    bust,
    waist,
    hips,
    hollow,
    note,
    custom_size_details,
  } = useSelector((store) => store.dress);
  const { fixed } = useSelector((store) => store.store);

  const dispatch = useDispatch();
  const dateNew = new Date();

  let day = String(dateNew.getDate()).padStart(2, "0");
  let month = String(dateNew.getMonth() + 1).padStart(2, "0");
  let year = dateNew.getFullYear();

  let currentDate = `${year}-${month}-${day}`;
  const [updatedOptions, setUpdatedOptions] = useState([]);
  const [updatedSizeOptions, setUpdatedSizeOptions] = useState([]);
  const [colorLabel, setColorLabel] = useState("");
  const [sizeLabel, setSizeLabel] = useState("");
  const [estimatedDate, setEstimatedDate] = useState("");
  const [customSize, setCustomSize] = useState({});
  const [colorId, setColorId] = useState("");
  const [sizeId, setSizeId] = useState("");
  const [newObject, setNewObject] = useState([]);
  const [dataChoosen, setDataChoosen] = useState(false);
  //const [datePicked, setDatePicked] = useState(currentDate);
  const [datePicked, setDatePicked] = useState();
  const [colorPicked, setColorPicked] = useState(false);
  const [sizePicked, setSizePicked] = useState(false);
  const [bustState, setBustState] = useState(bust);
  const [newObjectData, setNewObjectData] = useState([]);
  const [oldSelectedOptions,setOldSelectedOptions] = useState([]);

  const animatedComponents = makeAnimated();
  let selectedValues = [];
  let objectNew = [];
  let objectNewData = [];

  let selectedSizeValues = [];
  let today = new Date();
  const { Id } = useParams();
  const navigate = useNavigate();
  const clickHandler = () => {
    setShown(!shown);
  };
  const [shown, setShown] = useState(false);

  useEffect(() => {
    if(!shown) {
      // remove the values and refresh select
      setBustState('');
      validation.setFieldValue("waist", '');
      validation.setFieldValue("hips", '');
      validation.setFieldValue("hollow", ''); 
      validation.setFieldValue(
        "selected_size",
        oldSelectedOptions
      );
      setSizePicked(true);
      setSizeLabel(oldSelectedOptions.label);
      setSizeId(oldSelectedOptions.value);
    }
    else {
      validation.setFieldValue(
        "selected_size",
        {}
      );
    }
  },[shown]);

  useEffect(() => {
    if (Id) {
      dispatch(clearDressValues);
      dispatch(getDress({ id: Id }));
    }
  }, [Id]);

  useEffect(() => {
    if (estimated_days || estimated_months) {
      setEstimatedDate(
        moment(today).add({ days: estimated_days, months: estimated_months })._d
      );
    }
  }, [estimated_days, estimated_months]);


  const validation = useFormik({
    initialValues: {
      shown: false,
      selected_color: "",
      selected_size: {},
      dress_image: "",
      style_number: "",
      dress_name: "",
      dress_price: "",
      note: "",
      custom_size_details: "",
      bust: "",
      waist: "",
      hips: "",
      hollow: "",
    },
    validationSchema: Yup.object({
      shown: Yup.boolean(),
      selected_color: Yup.object().required("This Field is required!"),
      selected_size: Yup.object().when(
        "isRequired",
        (shown, validationSchema) =>
          shown
            ? validationSchema.nullable().notRequired()
            : validationSchema.required("This Field is required!")
      )
      .typeError("Please select a size or specify custom measurements"),
      custom_size_details: Yup.string().when(
        "isRequired",
        (shown, validationSchema) =>
          shown ? Yup.string().required() : validationSchema
      ),
    }),
    onSubmit: (values) => {
      dispatch(
        saveSingleDress({
          color: values.selected_color.label,
          size: values.selected_size.label,
          selected_color: values.selected_color.value,
          selected_size: values.selected_size.value,
          date: datePicked,
          dress_image: values.dress_image,
          style_number: values.style_number,
          dress_name: values.dress_name,
          dress_price: values.dress_price,
          dress_id: Id,
          custom_size_details: values.custom_size_details,
          hips: values.hips,
          waist: values.waist,
          hollow: values.hollow,
          bust: bustState,
          colorLabel: colorLabel,
          sizeLabel: sizeLabel,
          colorId: colorId,
          sizeId: sizeId,
          note: values.note,
        })
      );

      navigate(`/checkout/${Id}`, {
        state: {
          color: values.selected_color.label,
          size: values.selected_size.label,
          selected_color: values.selected_color.value,
          selected_size: values.selected_size.value,
          wear_date: datePicked,
          date: datePicked,
          dress_image: values.dress_image,
          style_number: values.style_number,
          dress_name: values.dress_name,
          dress_price: values.dress_price,
          dress_id: Id,
          note: values.note,
          custom_item: location.state,
        },
      });
    },
  });

  useEffect(() => {
    if (dress_image) {
      validation.setFieldValue("dress_image", dress_image);
    }
    if (note) {
      validation.setFieldValue("note", note);
    }

    if (colorIdNew) {
      validation.setFieldValue("selected_color", {
        value: colorIdNew,
        label: selected_colorLabel,
      });
    }

    if (sizeIdNew) {
      validation.setFieldValue("selected_size", {
        value: sizeIdNew,
        label: selected_sizeLabel,
      });
    }

    if (style_number) {
      validation.setFieldValue("style_number", style_number);
    }
    if (bust) {
      validation.setFieldValue("bust", bust);
    }
    if (hips) {
      validation.setFieldValue("hips", hips);
    }
    if (waist) {
      validation.setFieldValue("waist", waist);
    }
    if (hollow) {
      validation.setFieldValue("hollow", hollow);
    }
    if (custom_size_details) {
      validation.setFieldValue("custom_size_details", custom_size_details);
    }

    if (dress_name) {
      validation.setFieldValue("dress_name", dress_name);
    }
    if (dress_price) {
      validation.setFieldValue("dress_price", dress_price);
    }
    if (dress_colors) {
      dress_colors.map(({ id, label }) => {
        selectedValues.push({ value: id, label: label });
      });
      setUpdatedOptions(selectedValues);
    }
    if (dress_sizes) {
      if (dress_sizes) {
        dress_sizes.map(({ id, label }) => {
          selectedSizeValues.push({ value: id, label: label });
        });
        setUpdatedSizeOptions(
          selectedSizeValues.sort(
            (a, b) => parseFloat(a.label) - parseFloat(b.label)
          )
        );
      }
    }
  }, [
    dress_image,
    style_number,
    dress_name,
    dress_price,
    dress_colors,
    dress_sizes,
    sizeIdNew,
    colorIdNew,
  ]);

  useEffect(() => {
    objectNewData.push({ value: colorIdNew, label: selected_colorLabel });
    setNewObjectData(objectNewData);
  }, [colorIdNew, selected_colorLabel]);

  useEffect(() => {
    objectNew.push({ value: sizeIdNew, label: selected_sizeLabel });
    setNewObject(objectNew);
  }, [selected_sizeLabel, sizeIdNew]);

  useEffect(() => {
    if (colorPicked && sizePicked) {
      setDataChoosen(true);
    } else {
      setDataChoosen(false);
    }
  }, [colorPicked, sizePicked]);

  useEffect(() => {
    if (date) {
      //setDatePicked(date);
      setSizePicked(true);
      setColorPicked(true);
    }
  }, [date]);

  useEffect(() => {
    dispatch(setFixed(false));
    console.log("ff", fixed);
  }, [fixed]);

  useEffect(() => {
    if (custom_size_details || bust || waist || hips || hollow) {
      setShown(true);
    }
  }, [custom_size_details, bust, waist, hips, hollow]);

  return (
    <div className="scrollX widthmaxMed">
      <div className="width-row-header">
        <Header
          title="View Item"
          subtitle="Enter wear date, and choose color and size to proceed to checkout"
        />
      </div>
      {isLoading && <Loading />}
      {!isLoading && (
        <Container className="py-4 ">
          <Link
            to="/start-order"
            className="arrow"
            onClick={() => {
              dispatch(clearValues());
            }}
          >
            <img src={arrow} alt="arrow" />
            <span className="back-to-list px-2">back to list</span>
          </Link>
          <div className="container py-4 px-0 ">
            <div className="row gx-1">
              <div className="col-md-3 col-xs-12 col-sm-12 styleWidthImages">
                <img
                  className="order-image"
                  src={validation.values.dress_image}
                  alt="bride"
                />
              </div>

              <div className="col-md-9 col-xs-12 col-sm-12 mt-2 formWidth ps-lg-4">
                <Container className="row-style ">
                  <Form
                    onSubmit={(e) => {
                      e.preventDefault();
                      validation.handleSubmit();
                    }}
                  >
                    <p className="vieworder-nbr">
                      {validation.values.style_number}
                    </p>
                    <p className="vieworder-name">
                      {validation.values.dress_name}
                    </p>
                    <p className="vieworder-cost">
                      {validation.values.dress_price}
                    </p>
                    <div className="row gx-0 styleWidthView">
                      <div className="col-lg-7 col-sm-12 col-xs-12 ">
                        <FormInput
                          type="date"
                          name="date"
                          id="date"
                          labelText="Wear date"
                          className="form-control date-inputOrder"
                          value={datePicked}
                          onChange={(e) => {
                            setDatePicked(e.target.value);

                            document
                              .getElementById("date")
                              .addEventListener("change", (e) => {
                                e.target.blur();
                              });
                          }}
                          // onBlur={validation.handleBlur}
                          min={new Date().toLocaleDateString("en-ca")}
                        />
                        {/* {validation.touched.date && validation.errors.date ? (
                          <p className="error d-flex flex-row-reverse pt-1">
                            {validation.errors.date}
                          </p>
                        ) : null} */}
                      </div>
                    </div>
                    <div className="py-0 row gx-0">
                      <div className="py-2 ">
                        <p className="label-text mt-3 mb-0 ">Dress Color</p>
                      </div>

                      <div className="col-lg-7 col-sm-12 col-xs-12 ">
                        <Select
                          name="selected_color"
                          components={animatedComponents}
                          value={validation.values.selected_color}
                          defaultValue={validation.values.selected_color}
                          options={updatedOptions}
                          onChange={(selectedOption) => {
                            validation.setFieldValue(
                              "selected_color",
                              selectedOption
                            );

                            setColorPicked(true);
                            setColorLabel(selectedOption.label);
                            setColorId(selectedOption.value);
                          }}
                          className="select-colors  styleWidthView"
                        />
                        {validation.touched.selected_color &&
                        validation.errors.selected_color ? (
                          <p className="error d-flex flex-row-reverse pt-1">
                            {validation.errors.selected_color}
                          </p>
                        ) : null}
                      </div>
                    </div>
                    <div className="py-0 row gx-0">
                      <div className="py-2 ">
                        <p className="label-text mt-3 mb-0">Dress Size</p>
                      </div>
                      <div className="col-lg-7 col-sm-12 col-xs-12">
                        <Select
                          name="selected_size"
                          components={animatedComponents}
                          defaultValue={validation.values.selected_size}
                          options={updatedSizeOptions}
                          placeholder={shown ? " - " : "Select Size"}
                          isDisabled={shown ? true : false}
                          onChange={(selectedOption) => {
                            setOldSelectedOptions(selectedOption);
                            validation.setFieldValue(
                              "selected_size",
                              selectedOption
                            );
                            setSizePicked(true);
                            setSizeLabel(selectedOption.label);
                            setSizeId(selectedOption.value);
                          }}
                          value={validation.values.selected_size}
                          className="select-colors styleWidthView"
                        />
                        {validation.touched.selected_size &&
                        validation.errors.selected_size ? (
                          <p className="error d-flex flex-row-reverse pt-1">
                            {validation.errors.selected_size}
                          </p>
                        ) : null}
                      </div>
                    </div>
                    <button
                      type="button"
                      className="btn-secondary btn-style-view mt-4 "
                      onClick={() => {
                        clickHandler();
                      }}
                    >
                      CUSTOM MEASUREMENTS
                    </button>
                    <div className="row ">
                      <div className="col-lg-7 pe-0">
                        {shown && (
                          <CustomMeasurements
                            validation={validation}
                            setCustomSize={setCustomSize}
                            customSize={customSize}
                            setSizePicked={setSizePicked}
                            setBustState={setBustState}
                            bustState={bustState}
                            bust={bust}
                          />
                        )}
                      </div>
                    </div>
                    <div className="row gx-0 styleWidthView">
                      <div className="col-lg-7 col-sm-12 col-xs-12 ">
                        <Form.Control
                          type="text"
                          name="note"
                          labelText="Note"
                          placeholder="Note"
                          className="form-control mt-4"
                          value={validation.values.note}
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                        />
                        {validation.touched.note && validation.errors.note ? (
                          <p className="error d-flex flex-row-reverse pt-1">
                            {validation.errors.note}
                          </p>
                        ) : null}
                      </div>
                    </div>
                    {estimatedDate ? (
                      <div className="estimated_ship_date_container ms-0 d-flex">
                        <div className="estimated_ship_date">
                          ESTIMATED SHIP DATE
                        </div>
                        <div className="ship_date_value">
                          {formatFullMonthDate(estimatedDate)}
                        </div>
                      </div>
                    ) : (
                      ""
                    )}

                    {datePicked && moment(estimatedDate).isAfter(datePicked) ? (
                      <>
                        <div className="estimated_ship_date_container ms-0 ">
                          The estimated ship date is past the wear date, please
                          call us to discuss this order.
                        </div>
                        <div className="row widthForMed">
                          <div className="col-lg-7 col-sm-12 col-xs-12 d-flex flex-row-reverse mt-5 gx-0 ">
                            <button
                              className="btn-secondary btn-style-view"
                              type="submit"
                              disabled
                            >
                              PROCEED TO CHECKOUT
                            </button>
                          </div>
                        </div>
                      </>
                    ) : (
                      <div className="row widthForMed">
                        <div className="col-lg-7 col-sm-12 col-xs-12 d-flex flex-row-reverse mt-5 gx-0 ">
                          <button
                            className="btn-secondary btn-style-view"
                            type="submit"
                            disabled={!dataChoosen}
                          >
                            PROCEED TO CHECKOUT
                          </button>
                        </div>
                      </div>
                    )}
                  </Form>
                </Container>
              </div>
            </div>
          </div>
        </Container>
      )}
    </div>
  );
}

function CustomMeasurements({
  validation,
  setSizePicked,
  setBustState,
  bustState,
  bust,
}) {
  function otherChangeHandler(e) {
    setBustState(e.target.value);
  }
  useEffect(() => {
    if (validation.values.waist && bustState) {
      setSizePicked(true);
    } else {
      setSizePicked(false);
    }
  }, [validation, bustState]);

  return (
    <>
      <div className="d-flex">
        <p className="label-text mt-3 widthTitle">Bust</p>
        <Form.Control
          type="number"
          name="bust"
          labelText="Bust"
          placeholder="Bust"
          className="form-control mt-2 ms-5"
          value={bustState}
          onChange={otherChangeHandler}
        />
      </div>
      <div className="d-flex">
        <p className="label-text mt-3 widthTitle">Waist</p>
        <Form.Control
          type="number"
          name="waist"
          labelText="Waist"
          placeholder="Waist"
          className="form-control mt-2 ms-5"
          value={validation.values.waist}
          onChange={validation.handleChange}
          onBlur={validation.handleBlur}
        />
        {validation.touched.waist && validation.errors.waist ? (
          <p className="error d-flex flex-row-reverse pt-1">
            {validation.errors.waist}
          </p>
        ) : null}
      </div>
      <div className="d-flex">
        <p className="label-text mt-3 widthTitle">Hips</p>
        <Form.Control
          type="number"
          name="hips"
          labelText="Hips"
          placeholder="Hips"
          className="form-control mt-2 ms-5"
          value={validation.values.hips}
          onChange={validation.handleChange}
          onBlur={validation.handleBlur}
        />
        {validation.touched.hips && validation.errors.hips ? (
          <p className="error d-flex flex-row-reverse pt-1">
            {validation.errors.hips}
          </p>
        ) : null}
      </div>
      <div className="d-flex">
        <p className="label-text mt-3 widthTitle">Hollow to Hem</p>
        <Form.Control
          type="number"
          name="hollow"
          labelText="Hollow to Hem"
          placeholder="Hollow to Hem"
          className="form-control mt-2 ms-5"
          value={validation.values.hollow}
          onChange={validation.handleChange}
          onBlur={validation.handleBlur}
        />
        {validation.touched.hollow && validation.errors.hollow ? (
          <p className="error d-flex flex-row-reverse pt-1">
            {validation.errors.hollow}
          </p>
        ) : null}
      </div>
      <p className="label-text mt-3 mb-2">Other Measurement Details</p>
      <textarea
        name="custom_size_details"
        className="pb-4 pt-3 text-area-style rounded ps-4 g-0"
        defaultValue={validation.values.custom_size_details}
        onChange={validation.handleChange}
        onBlur={validation.handleBlur}
      />
      {validation.touched.custom_size_details &&
      validation.errors.custom_size_details ? (
        <p className="error pt-2">{validation.errors.custom_size_details} </p>
      ) : null}
    </>
  );
}
