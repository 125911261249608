import React, { useState, useEffect } from "react";
import { Button, Container,Form } from "react-bootstrap";
import Header from "../shared/Header";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import {
  getDressesFilter,
  getDresses,
  handleSort,
  getDressesPaginate,
  handleSearch,
} from "../../features/dress/dressSlice";
import { getStore } from "../../features/store/storeSlice";
import ViewImage from "../shared/ViewImage";
import SortButton from "../shared/SortButton";
import Pagination from "../shared/pagination";
import SearchForm from "../shared/SearchForm";
import eye from "../../assets/img/eye.svg";
import Loading from "../shared/Loading";
import StartCustomOrder from "../customItem/StartCustomOrder";
import { setFixed } from "../../features/store/storeSlice";
import searchsvg from "../../assets//img/search.svg";
const StartOrder = () => {
  const dispatch = useDispatch();
  const [allRows, setAllRows] = useState([]);
  const [showCustomItems, setShowCustomItems] = useState(false);
  const [prev, setPrev] = useState(null);
  const [next, setNext] = useState(null);

  const { store } = useSelector((store) => store.store);
  const {
    allDresses,
    dresses,
    isLoading,
    links,
    meta,
    sort,
    orderby,
    search,
  } = useSelector((store) => store.dress);
  const { storeName } = useSelector((store) => store.store);
  useEffect(() => {
    dispatch(
      getDresses({
        sort: sort,
        orderby: orderby,
        search: search,
      })
    );
  }, [sort, orderby, search]);

  useEffect(() => {
    if (dresses) {
      //setPrev(meta.path + "&order_dir=asc&order_by=name");
      //setNext(links.next + "&order_dir=asc&order_by=name");

      let dressArray = [];
      dresses.map((item) => {
        return dressArray.push({
          Id: item.id,
          Image: item.image,
          StyleNum: item.style_number,
          DressName: item.name,
          custom_items: item.custom_items,
        });
      });
      setAllRows(dressArray);
    }
  }, [dresses, isLoading, links, meta]);
  useEffect(() => {
    dispatch(setFixed(false));
  });

  useEffect(() => {
    if (store) {
      dispatch(getStore({ id: store.userable_id }));
    }
  }, [store]);

  useEffect(() => {
    dispatch(getDressesFilter({}))
  },[])

const handleCustomFilter = (e) => {
  let searchTerm = e.target.value;

  if(allDresses) {
    const filteredItems = allDresses.filter(item =>
      item.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
      item.style_number.includes(searchTerm)
    );

    console.log(filteredItems);
    if (filteredItems) {
      let arr = [];
      filteredItems.map((dress) => {
        return (
          <div key={dress.id}>
            {arr.push({
              Id: dress.id,
              Image: dress.image,
              StyleNum: dress.style_number,
              DressName: dress.name,
              custom_items: dress.custom_items,
            })}
          </div>
        );
      });
      setAllRows(arr);
    }
  }
}

  return (
    <Container className="p-0 widtSta scrollX">
      <div className="width-row-header flex-title">
        <p className="ms-4 mt-lg-4">{storeName}</p>
        <div className="orderHeader widthOrderHeaderTab">
          <Header
            title="Start an order"
            subtitle="Enter a style number or select a collection below to search our live and pending inventory."
          />
        </div>
      </div>

      {showCustomItems && (
        <StartCustomOrder setShowCustomItems={setShowCustomItems} />
      )}
      {!showCustomItems && (
        <>
          <div className="container my-4 toggle-style">
          <div class="searchForm"><div class="d-flex search">
            <input placeholder="Please enter style number or name" 
            type="text" id="formSearch" 
            className="ps-4 no-border search-input form-control"
            onChange={handleCustomFilter}
            />
              <button type="button" id="button-addon2" class="bg-transparent  box-shadow-none search-button btn btn-primary">
                <img className="margin-right-2" src={searchsvg} alt="search" /></button></div></div>

            <div className="d-flex align-items-center ms-4 toggle-input">
              <label
                className="form-check-label pt-1 d-flex align-items-center pe-2"
                htmlFor="flexSwitchCheckDefault"
              >
                Dresses
              </label>
              <div className="form-check form-switch mx-2">
                <input
                  className="form-check-input order-switch2"
                  type="checkbox"
                  id="flexSwitchCheckChecked"
                  onClick={(e) => {
                    if (e.target.checked) {
                      setShowCustomItems(true);
                    } else {
                      setShowCustomItems(false);
                    }
                  }}
                />
              </div>
              <label
                className=" form-check-label pt-1 d-flex align-items-center ps-2"
                htmlFor="flexSwitchCheckDefault"
              >
                Custom Items
              </label>
            </div>
          </div>
        </>
      )}

      {isLoading && <Loading />}
      {!isLoading && !showCustomItems && (
        <div className="container pe-0 ">
          <div className="row grid-header">
            <div className="col-lg-2 col-md-3 col-sm-3 col-xs-3 width-xs ms-4 title-width">
              Image
            </div>
            <SortButton
              sort={sort}
              label="Style Num."
              click={() =>
                dispatch(
                  handleSort({
                    sort: sort,
                    value: "style_number",
                  })
                )
              }
              rootClass="col-lg-2 col-md-3 col-sm-3 col-xs-3 width-xs width-style pe-0 ps-0 style-title title-width "
            />
            <SortButton
              sort={sort}
              label="Dress Name"
              value="name"
              click={() =>
                dispatch(
                  handleSort({
                    sort: sort,
                    value: "name",
                  })
                )
              }
              rootClass="col-lg-2 col-md-3 col-sm-3 col-xs-3 me-2 width-xs title-width dress-name width-style"
            />
          </div>
          {allRows.map((row) => {
            const { Id, Image, StyleNum, DressName, custom_items } = row;
            let urlLink = `/ViewOrder/${Id}`;
            let modalID = `#myModal${Id}`;
            let modalTarget = `myModal${Id}`;
            return (
              <div key={Id} className="grid-row-border ps-4 width-start-row ">
                <div className="row align-items-center">
                  <div className="col-lg-2 col-md-3 col-sm-3 col-xs-3 width-xs ">
                    {Image == null ? (
                      <div className="picWidth"></div>
                    ) : (
                      <>
                        <img
                          className="grid-image"
                          src={Image}
                          alt={DressName}
                          loading="lazy"
                        />
                        <img
                          src={eye}
                          className="grid-eye ms-1"
                          alt="eye"
                          data-bs-toggle="modal"
                          data-bs-target={modalID}
                          loading="lazy"
                        />

                        <ViewImage
                          header={DressName}
                          src={Image}
                          modalTarget={modalTarget}
                          labels={["Item Name", "Style Number"]}
                          body={[DressName, StyleNum]}
                        />
                      </>
                    )}
                  </div>
                  <div className="col-lg-2 col-md-3 col-sm-3 col-xs-3 grid-cell width-xs style-start ">
                    {StyleNum}
                  </div>
                  <div className="col-lg-2 col-md-3 col-sm-3 col-xs-3 grid-cell width-xs style-name ">
                    {DressName}
                  </div>
                  <div className="col-lg-2 col-md-2 col-sm-2 col-xs-2 width-btn">
                    <Link to={urlLink} state={custom_items}>
                      <Button className="btn-secondary height-30 ">View</Button>
                    </Link>
                  </div>
                </div>
              </div>
            );
          })}
          <div className="width-row-order widthLeft">
            {meta && (
              <Pagination
                active={meta.current_page}
                size={parseInt(meta.last_page)}
                step={2}
                previous={prev}
                next={next}
                dispatch={dispatch}
                handlePage={getDressesPaginate}
                path={meta.path}
              />
            )}
          </div>
        </div>
      )}
    </Container>
  );
};

export default StartOrder;
