import React, { useState, useEffect, useRef } from "react";
import Header from "../shared/Header";
import { Button, Container } from "react-bootstrap";
import { Link } from "react-router-dom";
import Pagination from "../shared/pagination";
import { useDispatch, useSelector } from "react-redux";
import SortButton from "../shared/SortButton";
import DateFilter from "../shared/DateFilter";
import Export from "../shared/Export";
import Loading from "../shared/Loading";
import {
  getStoreInvoice,
  handleFilter,
  getInvoicePaginate,
  exportInvoice,
  clearInvoiceValues,
  handleSort,
  setInvoiceData,
} from "../../features/invoice/invoiceSlice";
import { formatDate } from "../../helpers/helpers";
import { setInvoice, setFixed } from "../../features/store/storeSlice";

export default function InvoicesList() {
  const [allRows, setAllRows] = useState([]);
  const dispatch = useDispatch();
  const { store_id, invoice } = useSelector((store) => store.store);

  const {
    invoices,
    isLoading,
    links,
    meta,
    sort,
    orderby,
    status,
    from,
    to,
    factory_status,
  } = useSelector((store) => store.invoice);

  useEffect(() => {
    dispatch(
      getStoreInvoice({
        id: store_id,
        from: from,
        to: to,
        sort: sort,
        orderby: orderby,
        factory_status: factory_status,
      })
    );
  }, [sort, orderby, status, store_id, from, to]);

  useEffect(() => {
    dispatch(clearInvoiceValues);

    dispatch(
      getStoreInvoice({
        id: store_id,
        from: from,
        to: to,
        sort: sort,
        orderby: orderby,
        factory_status: factory_status,
      })
    );
  }, [sort, orderby, factory_status, from, to, store_id]);

  useEffect(() => {
    if (invoices && invoices[0]) {
      let invoiceArray = [];
      invoices.map((invoice) => {
        return invoiceArray.push({
          id: invoice.id,
          number: invoice.number,
          date: formatDate(invoice.created_at),
          price: invoice.store_package.meta.formatted_total,
          shipping: invoice.store_package.tracking_number,
          shippingCost: invoice.store_package.formatted_shipping_cost,
          status: invoice.status,
          Id: invoice.store_package_id,
          address: invoice.store_package.store,
        });
      });

      setAllRows(invoiceArray);
    } else {
      setAllRows([
        {
          id: "",
          number: "",
          date: "",
          price: "",
          shipping: "",
          shippingCost: "",
          status: "",
          Id: "",
          address: "",
        },
      ]);
    }
  }, [invoices, isLoading, links, meta, factory_status, from, to]);

  const checkBoxRef = useRef("");
  useEffect(() => {
    dispatch(setInvoice(true));
    dispatch(setFixed(true));
  }, []);

  return (
    <Container className="p-0 invoice scrollXOnSmallSize ScrollXHiddenOnMediumMobile">
      <div className={`${invoice === true && "mrgnTp"}`}>
        <div>
          <Header title="Invoices" subtitle="Manage all Invoices" />
        </div>
        <div className="mrgnTopTrans">
          <DateFilter handleFilter={handleFilter} />
        </div>
        <hr className="mx-4 hrTable hrInv" />
        <div className="container my-4 ps-3 pe-lg-5 d-flex justify-content color-secondary flex-wrap">
          <div className="exportwi">
            <Export
              label="Export Sample Orders Invoices"
              rootClass="d-flex align-items-center white12Text text-uppercase pt-2"
              action={exportInvoice}
              num={1}
            />
          </div>
          <div className="export2">
            <Export
              label="Export Repeat Orders Invoices"
              rootClass="d-flex align-items-center white12Text text-uppercase pt-2"
              action={exportInvoice}
              num={2}
            />
          </div>
          <div className="exp">
            <Export
              label="Export Total Invoices"
              rootClass="d-flex align-items-center white12Text text-uppercase pt-2"
              action={exportInvoice}
              num={""}
            />
          </div>
        </div>

        {isLoading && <Loading />}
        {!isLoading && (
          <div className="py-2 ms-lg-4">
            <table className="table table-responsive table-striped" style={{ width: "600px" }}>
              <thead>
                <tr>
                  {status === "Paid Fully" && (
                    <th style={{ width: "8%", wordWrap: "break-word" }}>
                      <Button className="bg-transparent no-border p-0">
                        Select All
                      </Button>
                    </th>
                  )}
                  <th style={{ width: "8%", wordWrap: "break-word" }}></th>
                  <th style={{  wordWrap: "break-word" }}>
                    <SortButton
                      sort={sort}
                      label="INVOICE #"
                      value="number"
                      click={() =>
                        dispatch(handleSort({ sort: sort, value: "number" }))
                      }
                    />
                  </th>
                  <th style={{ width: "20%", wordWrap: "break-word" }}>
                    <SortButton
                      sort={sort}
                      label="DATE"
                      value="created_at"
                      click={() =>
                        dispatch(handleSort({ sort: sort, value: "created_at" }))
                      }
                    />
                  </th>
                  <th style={{  wordWrap: "break-word" }}>
                    <SortButton
                      sort={sort}
                      label="Amount Charged"
                      value="total"
                      click={() =>
                        dispatch(handleSort({ sort: sort, value: "total" }))
                      }
                    />
                  </th>
                  <th style={{  wordWrap: "break-word" }}>
                    <SortButton
                      sort={sort}
                      label="SHIPPING TRACKING #"
                      value="tracking_number"
                      click={() =>
                        dispatch(
                          handleSort({ sort: sort, value: "tracking_number" })
                        )
                      }
                    />
                  </th>
                  <th style={{  wordWrap: "break-word" }}>
                    <SortButton
                      sort={sort}
                      label="Status"
                      value="status"
                      click={() =>
                        dispatch(handleSort({ sort: sort, value: "status" }))
                      }
                    />
                  </th>
                  <th style={{  wordWrap: "break-word" }}>
                    <SortButton
                      sort={sort}
                      label="Shipping Cost"
                      value="shippingCost"
                      click={() =>
                        dispatch(handleSort({ sort: sort, value: "shippingCost" }))
                      }
                    />
                  </th>
                </tr>
              </thead>
              <tbody>
                {allRows.map((row) => {
                  const {
                    id,
                    number,
                    date,
                    price,
                    shipping,
                    status,
                    Id,
                    address,
                    shippingCost,
                  } = row;
                  return (
                    <tr key={id}>
                      {status !== "Paid Fully" && (
                        <td>
                          <input
                            className="form-check-input"
                            type="checkbox"
                            id="flexCheckDefault"
                            ref={checkBoxRef}
                          />
                        </td>
                      )}
                      <td>
                        <Link
                          onClick={() => {
                            dispatch(
                              setInvoiceData({
                                number,
                                id,
                                address,
                                shippingCost,
                              })
                            );
                          }}
                          to={`/invoice-summary/${Id}`}
                          state={{
                            idl: id,
                            addressl: address,
                            numberl: number,
                            shippingCostl: shippingCost,
                          }}
                        >
                          {number}
                        </Link>
                      </td>
                      <td>{date}</td>
                      <td>{price}</td>
                      <td style={{ whiteSpace: "normal", wordWrap: "break-word" }}>{shipping}</td>
                      <td>{status}</td>
                      <td>{shippingCost}</td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        )}
        <div className="width-row-sale widthPag">
          {meta && (
            <Pagination
              active={meta.current_page}
              size={parseInt(meta.last_page)}
              step={2}
              previous={links.prev}
              next={links.next}
              dispatch={dispatch}
              handlePage={getInvoicePaginate}
              path={meta.path}
            />
          )}
        </div>
      </div>
    </Container>
  );
}
