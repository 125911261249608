import React, { useState, useEffect } from "react";
import LoginLogo from "../../assets/img/LoginLogo.svg";
import Logout from "../../assets/img/Logout.svg";
import Dropdown from "react-bootstrap/Dropdown";
import { Button, Form } from "react-bootstrap";
import searchIcon from "../../assets/img/search.svg";
import chevron from "../../assets/img/chevron.svg";
import plus from "../../assets/img/plus.svg";
import Pagination from "../shared/pagination";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { logoutUser } from "../../features/user/userSlice";
import { NavLink } from "react-router-dom";
import Loading from "../shared/Loading";
import YesNo from "../shared/modals/YesNo";
import error from "../../assets/img/error.svg";
import Password from "./modals/Password";
import { formatPhoneNumber } from "../../helpers/helpers";

import {
  getStores,
  getRetailersPaginate,
  handleSearch,
  handleFilter,
  removeStore,
  handleDelete,
  handlePassword,
} from "../../features/retailers/retailersSlice";
function ListOfRetailers() {
  const dispatch = useDispatch();
  const {
    stores,
    isLoading,
    links,
    meta,
    sort,
    orderby,
    status,
    search,
    per_page,
    showAll,
    isStoreDeleted,
    privateaccess,
  } = useSelector((store) => store.stores);
  const [value, setValue] = useState(10);
  const [allRows, setAllRows] = useState([]);
  const [deleteId, setDeleteId] = useState("");
  const [deleteModalShow, setDeleteModalShow] = useState(false);
  const [passwordModal, setPasswordModal] = useState(false);
  const [passType, setPassType] = useState();
  useEffect(() => {
    dispatch(
      getStores({
        sort: sort,
        orderby: orderby,
        status: status,
        search: search,
        per_page: per_page,
      })
    );
  }, [sort, orderby, status, search, per_page, showAll, isStoreDeleted]);
  const values = [10, 20, 50, 100];

  useEffect(() => {
    console.log(stores);
    if (stores) {
      setAllRows([]);
      let orderArray = [];
      stores.map((retailer) => {
        return orderArray.push({
          id: retailer.id,
          Retailers: retailer.name,
          PhoneNumber: retailer.phone,
          StoreAddress: retailer.address,
          BillingInfo: "**** **** **** 1234",
          WEBSITE: retailer.website,
        });
      });

      setAllRows(orderArray);
    }
  }, [stores, isLoading, links, meta]);

  return (
    <>
      {passwordModal && (
        <Password
          showModal={passwordModal}
          setShowModal={setPasswordModal}
          child={true}
          // setEditPaymentModalShow={setEditPaymentModalShow}
          setDeleteModal={setDeleteModalShow}
          handlePassword={handlePassword}
          type={passType}
          color="purple"
        />
      )}
      {deleteModalShow && (
        <YesNo
          show={deleteModalShow}
          setShow={setDeleteModalShow}
          icon={error}
          title="Delete Store"
          msg="Are you sure you want to delete the store?"
          color="red"
          child={false}
          handleAction={removeStore}
          currentId={deleteId}
        />
      )}
      <div className="container pe-0 ps-0">
        <div className="d-flex justify-content-between mt-5">
          <div>
            <object
              type="image/svg+xml"
              data={LoginLogo}
              className="img-size ps-2"
              aria-label="logo"
            />
          </div>

          <div className="d-flex">
            <div className="logout-logo">
              <img src={Logout} alt="logo" />
            </div>
            <NavLink to="/login-new" className={"text-decoration-none"}>
              <p
                className="sign-text ms-3 "
                onClick={(e) => {
                  dispatch(logoutUser());
                }}
              >
                SIGN OUT
              </p>
            </NavLink>
          </div>
        </div>
        <div className="bg-color">
          <p className="title-text text-light pt-3 ps-4">List of retailers</p>
          <p className="subtitle-text text-light pb-3 ps-4">
            Manage and edit retailers
          </p>
        </div>

        <div className="d-flex align-items-center ms-4 toggle-input width-section justify-content-between">
          <div>
            <div className="blue10Text mb-2 grid-cell-title">
              Filter Store Name
            </div>

            <div className="border-raduis">
              <Form.Group className="d-flex" controlId="formSearch">
                <Form.Control
                  className="ps-4 no-border search-input"
                  type="text"
                  placeholder="Enter Search Name"
                  onChange={(e) => {
                    dispatch(handleSearch({ search: e.target.value }));
                  }}
                />

                <Button
                  className="bg-transparent  box-shadow-none search-button"
                  id="button-addon2"
                >
                  <img
                    className="margin-right-2"
                    src={searchIcon}
                    alt="search"
                  />
                </Button>
              </Form.Group>
            </div>
          </div>
          <div className="ms-4">
            <div className="blue10Text mb-2 grid-cell-title">
              Records Per Page
            </div>
            <Dropdown className="dropdownbutton grayBorder">
              <Dropdown.Toggle
                className="bg-transparent dropdownbutton no-border justify-content-between d-flex align-items-center "
                id="dropdown-basic"
              >
                <div className="light-gray10text d-flex align-items-center ">
                  {value}
                </div>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="11.9"
                  height="7"
                  viewBox="0 0 11.9 7"
                >
                  <path
                    id="chevron"
                    d="M7.332,5.949a.66.66,0,0,1-.119.385l-3.5,5.25a.7.7,0,0,1-.581.315h-2.1a.7.7,0,0,1-.7-.7.66.66,0,0,1,.119-.385L3.692,5.949.451,1.084A.66.66,0,0,1,.332.7a.7.7,0,0,1,.7-.7h2.1a.7.7,0,0,1,.581.315l3.5,5.25a.66.66,0,0,1,.119.385Zm0,0"
                    transform="translate(11.899 -0.332) rotate(90)"
                    fill="#b8998d"
                  />
                </svg>
              </Dropdown.Toggle>

              <Dropdown.Menu>
                {values.map((value, index) => (
                  <Dropdown.Item
                    key={index}
                    onClick={(e) => {
                      dispatch(
                        handleFilter({ per_page: parseInt(e.target.text) })
                      );
                      setValue(e.target.text);
                    }}
                  >
                    {value}
                  </Dropdown.Item>
                ))}
              </Dropdown.Menu>
            </Dropdown>
          </div>
          <Link to="/add-store">
            <button className="btn-add btn-width mt-2">
              <img src={plus} alt="add" className="pe-2" />
              add store
            </button>
          </Link>
        </div>
        <hr className="ms-4 width-section" />

        {isLoading && <Loading />}
        {!isLoading && (
          <>
            <div className="row row-header align-items-center py-4 px-3 mx-0 grid-cell-title width-header">
              <div className="col-md-1 pe-0">
                RETAILERS
                {/* <img src={chevron} alt="chevron" className="ms-2" /> */}
              </div>
              <div className="col-md-2 ps-5">
                PHONE NUMBER
                <img src={chevron} alt="chevron" className="ms-2" />
              </div>

              <div className="col-md-2 pe-0 ps-2">
                STORE ADDRESS
                <img src={chevron} alt="chevron" className="ms-2" />
              </div>
              <div className="col-md-2 ps-5">
                WEBSITE
                <img src={chevron} alt="chevron" className="ms-2" />
              </div>
              <div className="col-md-2 ps-5">
                Billing Information
                <img src={chevron} alt="chevron" className="ms-2" />
              </div>
              <div className="col-md-1"></div>
            </div>

            {allRows.map((row) => {
              const {
                id,
                WEBSITE,
                StoreAddress,
                PhoneNumber,
                Retailers,
                BillingInfo,
              } = row;
              let urlLinkEdit = `/edit-new-store/${id}`;
              return (
                <div
                  className="row  row-container align-items-center px-3  mx-0  py-2 border-recent width-row-store mb-4 ms-4"
                  key={id}
                >
                  <div className="col-md-1 pe-0 font-label"> {Retailers}</div>
                  <div className="col-md-2 ps-5 font-label">
                    {formatPhoneNumber(PhoneNumber)}
                  </div>
                  <div className="col-md-2 pe-0 ps-4 font-label">
                    {StoreAddress}
                  </div>
                  <div className="col-md-3 ps-5 font-label"> {WEBSITE}</div>
                  <div className="col-md-1 ps-4 font-label"> {BillingInfo}</div>
                  <div className="col-md-1 d-flex">
                    <Link to={urlLinkEdit}>
                      <button className="light-purple-round-btn white12meduim table-btn3 ms-2">
                        Edit Store
                      </button>
                    </Link>

                    <button
                      className="light-purple-round-btn white12meduim table-btn3 ms-2"
                      onClick={() => {
                        if (privateaccess) {
                          setPassType("delete");
                          setDeleteModalShow(true);
                          dispatch(handleDelete(id));
                          setDeleteId(id);
                        }
                      }}
                    >
                      Delete Store
                    </button>
                  </div>
                </div>
              );
            })}
            {meta && (
              <Pagination
                active={meta.current_page}
                size={parseInt(meta.last_page)}
                step={2}
                previous={links.prev}
                next={links.next}
                dispatch={dispatch}
                handlePage={getRetailersPaginate}
                path={meta.path}
              />
            )}
          </>
        )}
      </div>
    </>
  );
}

export default ListOfRetailers;
