import React, { useState, useEffect, useRef } from "react";
import Header from "../shared/Header";
import { Button, Container } from "react-bootstrap";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import DateFilter from "../shared/DateFilter";
import SortButton from "../shared/SortButton";
import Pagination from "../shared/pagination";
import Export from "../shared/Export";

import {
  getStoreTransaction,
  handleFilter,
  getInvoicePaginate,
  exportTransactions,
  handleSort,
} from "../../features/invoice/invoiceSlice";
import { formatDate } from "../../helpers/helpers";

import { setTransaction, setFixed } from "../../features/store/storeSlice";
export default function TransactionHistoryList() {
  const [allRows, setAllRows] = useState([]);
  const dispatch = useDispatch();
  const { store_id, transaction } = useSelector((store) => store.store);
  const {
    transactions,
    links_h,
    meta_h,
    sort,
    from,
    to,
    orderby_h,
  } = useSelector((store) => store.invoice);

  useEffect(() => {
    dispatch(
      getStoreTransaction({
        id: store_id,
        from: from,
        to: to,
        sort: sort,
        orderby_h: orderby_h,
      })
    );
  }, [sort, orderby_h, from, to, store_id]);

  useEffect(() => {
    if (transactions && transactions[0]) {
      let invoiceArray = [];
      transactions.map((invoice) => {
        return invoiceArray.push({
          Id: invoice.id,
          Invoice: invoice.invoice.number,
          Date: formatDate(invoice.created_at),
          AmountBiled: invoice.formatted_amount,
          order: invoice.order,
          invoice: invoice.invoice,
        });
      });
      setAllRows(invoiceArray);
    } else {
      setAllRows([
        {
          Id: "",
          Invoice: "",
          Date: "",
          AmountBiled: "",
          order: "",
          invoice: "",
        },
      ]);
    }
  }, [transactions]);

  const checkBoxRef = useRef("");

  // useEffect(() => {
  //   if (checked) checkBoxRef.current.classList.add("brown-border");
  //   else checkBoxRef.current.classList.remove("brown-border");
  // }, [checked]);

  useEffect(() => {
    dispatch(setTransaction(true));
    dispatch(setFixed(true));
  }, []);
  return (
    <Container className="p-0 scrollXOnTablet scrollX mxwidth">
      <div className={`${transaction === true && "mrgnTp"}`}>
        <div className="width-row-header">
          <Header
            title="Transaction History"
            subtitle="Manage all transactions"
          />
        </div>
        <div className="mt-5 mb-lg-4 pe-5 d-flex mrgnTopTrans">
          <DateFilter handleFilter={handleFilter} />
        </div>
        <hr className="ms-5 hrTable expoHr" />
        <div className="container my-4 ps-2 pe-5 d-flex justify-content color-secondary ps-4 marginExpo">
          <Button className="bg-transparent no-border mx-2 btn-hover">
            <Export
              num={"1"}
              label="Export Sample Order Transactions"
              action={exportTransactions}
              exportClass={"exportTrns widthTransc"}
            />
          </Button>
          <Button className="bg-transparent no-border mx-2 btn-hover btnEx">
            <Export
              num={"2"}
              label="Export Repeat Order Transactions"
              action={exportTransactions}
              exportClass={"widthTransc"}
            />
          </Button>
          <Button className="bg-transparent no-border">
            <div className="marginExpor mrgnR">
              <Export
                num={""}
                label="Export All Transactions"
                action={exportTransactions}
                exportClass={"widthTransc mrgnTab"}
              />
            </div>
          </Button>
        </div>
        <div className="container">
        <div className="py-2 ms-lg-4">
          <table className="table table-responsive table-striped" style={{ 'max-width': "350px" }}>
            <thead>
              <tr>
                <th scope="col" style={{ width: "10%" }}>
                  <Button className="bg-transparent grid-header no-border ms-0 p-0">
                    Select All
                  </Button>
                </th>
                <th scope="col" style={{ width: "20%" }}>
                  <SortButton
                    sort={sort}
                    label="Date"
                    value="created_at"
                    click={() =>
                      dispatch(handleSort({ sort: sort, val: "created_at" }))
                    }
                  />
                </th>
                <th scope="col" style={{ width: "15%" }}>
                  <SortButton
                    sort={sort}
                    label="INVOICE #"
                    value="id"
                    click={() => dispatch(handleSort({ sort: sort, val: "id" }))}
                  />
                </th>
                <th scope="col"  style={{ width: "15%" }}>
                  <SortButton
                    sort={sort}
                    label="Amount Charged"
                    value="amount"
                    click={() =>
                      dispatch(handleSort({ sort: sort, val: "amount" }))
                    }
                  />
                </th>
              </tr>
            </thead>
            <tbody>
              {allRows.map((row) => {
                const { Id, Invoice, Date, AmountBiled } = row;
                return (
                  <tr key={Id}>
                    <td>
                      <div className="form-check">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          id={`checkbox-${Id}`}
                          ref={checkBoxRef}
                        />
                      </div>
                    </td>
                    <td>
                      <Link to={`/invoice-summary/${Id}`}>{Date}</Link>
                    </td>
                    <td>
                      <Link to={`/invoice-summary/${Id}`}>{Invoice}</Link>
                    </td>
                    <td>
                      <Link to={`/invoice-summary/${Id}`}>{AmountBiled}</Link>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
        </div>
        <div className="width-row-order marginLeftE">
          {meta_h && (
            <Pagination
              active={meta_h.current_page}
              size={parseInt(meta_h.last_page)}
              step={2}
              previous={links_h.prev}
              next={links_h.next}
              dispatch={dispatch}
              handlePage={getInvoicePaginate}
              path={meta_h.path}
            />
          )}
        </div>
      </div>
    </Container>
  );
}
