import React, { useState, useEffect } from "react";
import { Accordion, Card, Button, Container } from "react-bootstrap";
import visa from "../../../assets/img/visa.png";
import { useDispatch, useSelector } from "react-redux";
import ViewImage from "../../shared/ViewImage";
import eye from "../../../assets/img/eye.svg";
import moment from "moment";
import momenttimezone from 'moment-timezone';
import { formatPhoneNumber } from "../../../helpers/helpers";

import {
  createOrder,
  clearOrderValues,
} from "../../../features/order/orderSlice";
import { useNavigate } from "react-router-dom";
import { getAddress } from "../../../features/address/addressSlice";
import { getColorById, getSizeById } from "../../../utils/helpers";

export default function OrderSummary({ CustomToggle, item }) {
  const [currentId, setCurrentId] = useState("");
  const [state, setState] = useState("");
  const [valuesChecked, setValuesChecked] = useState(true);
  const [isClicked, setIsClicked] = useState(true);
  const [serverValidation,setServerValidation] = useState([]);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { name, date } = useSelector((store) => store.customItem);
  const { purchase_order_number, type, PurchaseOrderValues } = useSelector(
    (store) => store.order
  );
  const { store_id, store } = useSelector((store) => store.store);
  const { address, SelectedAddressId } = useSelector((store) => store.address);
  const { paymentM } = useSelector((store) => store.payment);


  useEffect(() => {
    if (SelectedAddressId) dispatch(getAddress({ id: SelectedAddressId }));
  }, [SelectedAddressId]);

  let modalID = `#myModal${currentId}`;
  let modalTarget = `myModal${currentId}`;

  useEffect(() => {
    dispatch(clearOrderValues());

    setCurrentId(item.id);
  }, [item.id, address]);

  const handleSubmitOder = () => {
        // adding the slack notification
        const msgdate = new Date();
        // Convert the date time to GMT -4
        const transformedDateTime = momenttimezone(msgdate).tz("America/Chicago").format();
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
    
        var raw = JSON.stringify({
          "subject": "Client Whole Sale React App, Somone did an order",
          "text": `An order was placed on ${transformedDateTime} order object send is {
            type: ${type},
            purchase_order_number: ${purchase_order_number},
            item_type: Custom Items,
            custom_item_id: ${currentId},
            custom_item_name: ${name}
            client_phone: ${address.phone},
            wear_date: ${date},
            store: ${store.name},
            payment_method: ${paymentM},
          }`
        });

        var requestOptions = {
          method: 'POST',
          headers: myHeaders,
          body: raw,
          redirect: 'follow'
        };
        
    
        fetch("https://us-central1-bridit-983ab.cloudfunctions.net/postToSlack", requestOptions)
        .then(response => response.text())
        .then(result => {
      console.log("results is ", result);
      return fetch("https://us-central1-bridit-983ab.cloudfunctions.net/sendMail", requestOptions);
        })
        .then(response => response.text())
        .then(result => console.log(result))
        .catch(error => console.log('error', error));

    setIsClicked(false);

    dispatch(
      createOrder({
        type: type === "Re-Order" ? 2 : 1,
        purchase_order_number,
        item_type: 2,
        item_id: currentId,
        address: SelectedAddressId,
        wear_date: date,
        store: store_id,
        payment_method: paymentM,
      })
    ).then((payload) => {
      console.log("payload output", payload);
      if(payload.payload.success) {
        //adding the slack notification
        const msgdate = new Date();
        // Convert the date time to GMT -4
        const transformedDateTime = momenttimezone(msgdate).tz("America/Chicago").format();
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        
        var raw = JSON.stringify({
          "subject": "SUCCESSFUL ORDER",
          "text": `the order was successful on ${transformedDateTime} order successs messages {
        type: ${type},
        purchase_order_number: ${purchase_order_number},
        item_type: Custom Items,
        custom_item_id: ${currentId},
        custom_item_name: ${name}
        client_phone: ${address.phone},
        wear_date: ${date},
        store: ${store.name},
        payment_method: ${paymentM},
          }`
        });
        
        var requestOptions = {
          method: 'POST',
          headers: myHeaders,
          body: raw,
          redirect: 'follow'
        };
        
        fetch("https://us-central1-bridit-983ab.cloudfunctions.net/postToSlack", requestOptions)
          .then(response => response.text())
          .then(result => {
            console.log("results is ", result);
            return fetch("https://us-central1-bridit-983ab.cloudfunctions.net/sendMail", requestOptions);
          })
          .then(response => response.text())
          .then(result => {
            console.log(result);
            navigate("/sales-order");
          })
          .catch(error => {
            console.log('error', error);
            navigate("/sales-order");
          });
      } else {
        const {data} = payload.payload.response;
        setIsClicked(true);
        setServerValidation(data.data);

        //adding the slack notification
        const msgdate = new Date();
        // Convert the date time to GMT -4
        const transformedDateTime = momenttimezone(msgdate).tz("America/Chicago").format();
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        
        var raw = JSON.stringify({
          "subject": "FAILED ORDER",
          "text": `An order failed on ${transformedDateTime} order error messagess ${data.data.join(", ")}  {
        type: ${type},
        purchase_order_number: ${purchase_order_number},
        item_type: Custom Items,
        custom_item_id: ${currentId},
        custom_item_name: ${name}
        client_phone: ${address.phone},
        wear_date: ${date},
        store: ${store.name},
        payment_method: ${paymentM},
          }`
        });
        
        var requestOptions = {
          method: 'POST',
          headers: myHeaders,
          body: raw,
          redirect: 'follow'
        };
        
        fetch("https://us-central1-bridit-983ab.cloudfunctions.net/postToSlack", requestOptions)
          .then(response => response.text())
          .then(result => {
        console.log("results is ", result);
        return fetch("https://us-central1-bridit-983ab.cloudfunctions.net/sendMail", requestOptions);
          })
          .then(response => response.text())
          .then(result => console.log(result))
          .catch(error => console.log('error', error));
      }
      //console.log("Payload is ", payload.payload.response);
    });
  };
  useEffect(() => {
    if (address) {
      var addressState = Object.values(address.state);
      setState(addressState);
    }
  }, [address]);

  useEffect(() => {
    if (!PurchaseOrderValues) {
      setValuesChecked(false);
    } else {
      setValuesChecked(true);
    }
    if (!isClicked) {
      setValuesChecked(false);
    }
  }, [PurchaseOrderValues, isClicked]);

  return (
    <div className="pb-4">
      <Card className="card mb-2 pe-5">
        <Card.Header className="bg-white">
          <CustomToggle
            eventKey="3"
            title="Order Summary"
            subtitle="You are one step away, Please review order"
          ></CustomToggle>
          {/* <div className="d-flex">
            <div className="card-number me-4">
              <p className="mt-1">4</p>
            </div> */}
          {/* <div className="d-block">
              <div className="accordion-title">Order Summary</div>
              <div className="accordion-subtitle ">
                You are one step away, Please review order
              </div>
            </div> */}
          {/* </div> */}
        </Card.Header>
        <Accordion.Collapse eventKey="3">
          <Card.Body className="d-flex row ms-5">
            <hr className="hrAccordionrP" />
            <div className="col-lg-8 pt-2">
              <div className="row grid-header mt-4 p-0">
                <div className="col-lg-2 ms-4 ">IMAGE</div>
                <div className="col-lg-3 ps-2">name</div>
                <div className="col-lg-3 ms-2">Description</div>
                <div className="col-lg-2 ms-2">wear date</div>
              </div>
              <div className="grid-row-border-order ps-4">
                <div className="row align-items-center">
                  <div className="col-lg-2 col-md-3 col-12">
                    {item ? (
                      <img
                        className="grid-image position-relative"
                        src={item.image}
                        alt="img"
                      />
                    ) : null}

                    <img
                      src={eye}
                      className="ms-1 grid-eye mt-4 position-absolute"
                      alt="eye"
                      data-bs-toggle="modal"
                      data-bs-target={modalID}
                    />

                    {item ? (
                      <ViewImage
                        header={item.name}
                        src={item.image}
                        modalTarget={modalTarget}
                        labels={["Item Name"]}
                        body={[item.name]}
                      />
                    ) : null}
                  </div>

                  {item ? <div className="col-lg">{item.name}</div> : null}
                  {item ? (
                    <div className="col-lg ">{item.description}</div>
                  ) : null}
                  <div className="col-lg ">
                    {moment(date).format("MM/DD/YYYY")}
                  </div>
                </div>
              </div>

              <div className="border mt-2 lh-1 pb-2">
                <div className="row px-2 pb-4 ">
                  {address ? (
                    <div className="second-title col-lg-11 px-4 mt-4">
                      {address.full_name}
                    </div>
                  ) : (
                    ""
                  )}
                  <div className=" row d-flex">
                    {address ? (
                      <>
                        <address className="paragraph col-lg-auto px-4 mb-2">
                          {address.street}
                          {", "} {address.city}
                          {", "} {state} {address.zip}
                        </address>
                      </>
                    ) : (
                      ""
                    )}
                  </div>

                  {address ? (
                    <div className="paragraph px-4 pb-2">
                      {formatPhoneNumber(address.phone)}
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              </div>

              <div className="border  pb-4 mt-2 lh-1 align-items-center">
                <div className="row pt-4 ">
                  <div className="col-lg-11 grid-row-visa align-items-center">
                    <div className="col-lg-auto pb-2 mx-2 px-3 ">
                      <img src={visa} alt="visa" />
                    </div>
                    <div className="col-lg-auto ps-2 ">**** **** **** 1238</div>
                    <div className=" col-lg-4 ps-4  ">Expires on 12/22</div>
                  </div>
                </div>
              </div>
              <div className="border mt-2">
                <div className="row  ">
                  <div className="col-lg-11">
                    <div className="px-4 details-header">
                      purchase order number
                    </div>
                    <div className="px-4">{purchase_order_number}</div>
                  </div>
                </div>
                <p className="px-4 details-header mt-3">type of order</p>
                <p className="px-4 pb-4">{type}</p>
              </div>
            </div>
            <div className="col-lg-3 pt-5">
              <Container className="bg-light head-container">
                <p className="summary-title ps-2">summary</p>
                <hr className="mx-0" />
                <p className="ps-2">TOTAL</p>
                {item ? (
                  <p className="sales-summary-cost">
                    {item.formatted_wholesale_price}
                  </p>
                ) : null}
                <p className="summary-text ps-2">
                  This is an estimated cost, which is subject to change in the
                  sales order if additional charges (for example for larger size
                  wedding dresses, or custom measurements, or a rush fee on case
                  by case basis) are applied
                </p>
              </Container>

              <Button
                className="btn-secondary summary-btn "
                onClick={handleSubmitOder}
                disabled={!valuesChecked}
              >
                PLACE ORDER
              </Button>
              {PurchaseOrderValues === false ? (
                <p className="estimated_ship_date_container ms-0">
                  Please Fill Purchase Order Section
                </p>
              ) : (
                ""
              )}
              <p className="estimated_ship_date_container ms-0">
                  {serverValidation &&
                  serverValidation.map((error,index)=> {
                    return(<>{error}<br/></>)
                  })
                  }
                </p>
            </div>
          </Card.Body>
        </Accordion.Collapse>
      </Card>
    </div>
  );
}
